import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { Card } from "../components/Card"
import CBN from "../assets/cbn.png"
import PK from "../assets/pk.png"
import JAGAN from "../assets/jagan.png"
import { Login } from './Login';
import title from '../assets/title.png'
import { db } from "../firebase"
import { doc, collection, query, getDoc, setDoc, orderBy, updateDoc, increment, onSnapshot } from "firebase/firestore";
import GreetingMessage from './Greetings'
import {jwtDecode} from 'jwt-decode';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { incrementUserCutouts } from '../utilities/utility';
import Leaderboard from "./LeaderBoard"


const images  = 
    {
      "PK":PK,
      "JAGAN":JAGAN,
      "CBN": CBN
    }

function Cutouts(props) {
  const {user, setShowLogin} = props
  const navigate = useNavigate();
  const [addCounter, setAddCounter] = useState(0);
  const [cutouts, setCutouts] = useState([])
  const coCollection = collection(db, "cutout");
  const activityCollection = collection(db, "acitivity");
  const [cutout, setCutout] = useState("");
  const [msg, setMsg] = useState()
  const [userCutouts, setUserCutouts] = useState(0);

// Create a query against the collection.

const styles = {
  cutoutCountContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px', // Space between CutoutCount and Profile Button
    padding: '8px 12px',
    backgroundColor: "#ababab", color: "#000", border: "2px solid #fff",
    borderRadius: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position:"absolute",
    right: 40
  },
  cutoutCountText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#333',
  },
};

  
  useEffect(()=>{
    const getCutoutDocs = async() => {
      const q = query(coCollection, orderBy("votes", "desc"));
      // const querySnapshot = await getDocs(q)
      // console.log(" querySnapshot => ", querySnapshot.docs);
      // setCutouts( querySnapshot.docs?.map( (doc) =>  doc.data()));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
            cities.push(doc.data());
        });
        setCutouts(cities)
      });
    };
    setShowLogin(false)
    getCutoutDocs()
  }, [])

  useEffect(()=>{
    if(user && addCounter){
      setAddCounter(addCounter - 1)
      addCutoutAPI()
    }
  })

  useEffect(()=>{
    // console.log("cutouts", cutouts)
  },[cutouts])

  useEffect(()=>{
    
      if (!user?.credential) return;
      const {email} = jwtDecode(user.credential)
      if(!email) return;
      
      const participantsDocRef = doc(db, "cutout", "participants");
      
      const unsubscribe = onSnapshot(participantsDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const participants = docSnap.data().participants || [];
          const user = participants.find(p => p.email === email);
          if (user) {
            setUserCutouts(user.cutouts);
          }
        }
      });
  
      return () => unsubscribe(); // Cleanup listener on unmount
    
  },[user])

  
  const addCutout = (coKey) => {
    if(user){
      addCutoutAPI(coKey)
      setShowLogin(false)
    } else {
      setShowLogin(true)
      setAddCounter(addCounter + 1)
    }
  }

  const transformEmail = (email) => {
    // Replace all dots with an empty string
    let transformedEmail = email.replace(/\./g, "@");
    
    return transformedEmail;
}

  const addCutoutAPI = async (coKey) => {
      const formattedDate = getFormattedDate()
      const cutoutRef = doc(db, "cutout", coKey?.toLowerCase() || cutout.toLowerCase());

      const activityRef = doc(db, "main", 'co'+formattedDate);

      const activityDoc = await getDoc(activityRef);
      let activityData = [];

      const userData = jwtDecode(user.credential);
      const email = transformEmail(userData.email)
      

      if (!activityDoc.exists()) {
        // Document does not exist, create it with the key and count 1
        await setDoc(activityRef, { [email]: 1 });
        await updateDoc(cutoutRef, {
          votes: increment(1)
        });
        incrementUserCutouts(userData.email, 0.25)
      } else {
        // Document exists, update it
        const data = activityDoc.data();
  
        if (data[email]) {
          // Key already exists, increment its count by 1
          if(data[email] < 5 ){
            await updateDoc(activityRef, { [email]: data[email] + 1 });
            await updateDoc(cutoutRef, {
              votes: increment(1)
            });
            incrementUserCutouts(userData.email, 0.25)
          } else {
            setMsg("Great! You rocked it today, you can again vote tomorrow")
          }
        } else {
          // Key does not exist, add it with count 1
          
            await updateDoc(activityRef, { [email]: 1 });
            await updateDoc(cutoutRef, {
              votes: increment(1)
            });
            incrementUserCutouts(userData.email, 0.25)
        }
      }

      
      // if(emails){
      //   emails.push(email);
        // await updateDoc(activityRef, {
        //   emails: emails,
        // });
        
          
        
        
      // } 
      // else {
      //   setMsg("Your daily voting limit crossed, you can again vote tomorrow")
      // }
  }

  function getFormattedDate() {
    const today = new Date();
  
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
  
    const formattedDate = `${day}${month}${year}`;
    
    return Number(formattedDate);
  }

  const handleOpenProfile = () => {
    if(user){
      navigate("/profile")
    } else {
      navigate("/login")
    }
  }
  
  return (
    <>
      <header style={{height:"60px", justifyContent:"start", display:"flex", alignItems:"left", color:"white", paddingTop: 8, }}>
        {/* <h1 style={{ boxShadow: "rgb(240 125 125 / 25%) 12px 12px 16px 0px, rgb(150 222 255 / 30%) -8px -8px 12px 0px" , padding: "4px", borderRadius: "10px" }}> Cutout </h1> */}
        <img alt={"Cutout"} style={{ boxShadow: "rgb(240 125 125 / 25%) 12px 12px 16px 0px, rgb(150 222 255 / 30%) -8px -8px 12px 0px" , padding: "4px", borderRadius: "10px" , height:"90%"}} src={title} />
        <div className="cutout-count" style={{ color: "#f8b400", fontWeight: "bold", marginRight: "10px", position:"absolute", right:48 }}>
      {userCutouts > 0 ? userCutouts : ""}
      </div>
        <Button style={{ backgroundColor: "rgb(71 67 67)", color: "#fff", fontWeight: "bold", fontSize: "14px", border: "none"}}
          className="position-absolute top-0 end-0 m-3" onClick={handleOpenProfile}> {user ? "Profile" : "Login"} 
        </Button>
      </header>
      {/* <GreetingMessage /> */}
      <div className="overflow-hidden whitespace-nowrap rounded-lg p-2">
      <div className="animate-marquee inline-block min-w-full">
        <span className="text-lg font-semibold" style={{color:'rgb(231 196 59)'}}> 
        Online Cutout for your favourite Leader
        </span>
      </div>
      <style jsx>{`
        @keyframes marquee {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
        }
        .animate-marquee {
          animation: marquee 10s linear infinite;
        }
      `}</style>
    </div>
      {msg && <div style={{color: 'red', padding:"8px 8px", marginTop: 8, border:"1px solid red"}}> Great! You rocked it today, you can again vote tomorrow </div>}
      <Row  gap={3} style={{backgroundColor: "black",  display: "flex", alignItems:"flex-end", "height":"50vh", textAlign: "center", marginBottom: 24}}>
        {cutouts.map((leader, idx)=>{
          return <Col xs={idx === 0 ? 5 : 5 - idx}> <Card image={images[leader.image]} order={idx} love={leader.votes} actionLabel={"Add"} cardAction={addCutout} coKey={leader.image} color={leader.color} setCutout={setCutout} smallerText={idx === 2 ? true : false} /> </Col>
        })}
      </Row>
      <div style={{background:'#242325', margin:"16px 0px 0px",padding:8}}>
        <Row style={{justifyContent:"center", fontWeight:700, color:"white",  paddingTop:4}}>
          {cutouts?.[0]?.name}
        </Row>
        
        <Row style={{color:cutouts?.[0]?.color, justifyContent:"center", fontWeight:500, paddingTop:4}} >
          {cutouts?.[0]?.party}
        </Row> 
      </div>
    
    

      <div style={{background:'#242325', margin:"16px 0px 0px",padding:8}}>
        <Leaderboard />
      </div>
    </>
  );
}

export default Cutouts;
