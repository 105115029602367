import React, { useEffect, useState } from 'react';
import { getFirestore, collection, doc, getDoc,  getDocs } from 'firebase/firestore';
import { Card, Table } from 'react-bootstrap';

const db = getFirestore();

export default function Leaderboard() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
    const coCollection = collection(db, "cutout");
    const participantsDocRef = doc(coCollection, "participants");
    const participantsSnap = await getDoc(participantsDocRef);
    
        const participants = participantsSnap?.data()?.participants || [];
      
    // const cutoutVotes = cutoutsSnap.docs.map(doc => ({
    //     name: doc.data().name,
    //     points: doc.data().votes || 0,
    //   }));

    //   const participantCutouts = participantsSnap.docs.map(doc => ({
    //     name: doc.data().name,
    //     points: doc.data().cutouts || 0,
    //   }));

      const combined = [ ...participants].reduce((acc, user) => {
        const existing = acc.find(u => u.name === user.name);
        if (existing) {
          existing.cutouts += user.cutouts;
        } else {
          acc.push(user);
        }
        return acc;
      }, []);

      const sorted = combined.sort((a, b) => b.cutouts - a.cutouts).slice(0, 20);
      setUsers(sorted);
    };

    fetchUsers();
  }, []);

  return (
    <div className="p-4" style={{ backgroundColor: '#000', color: 'red', minHeight: '100vh' }}>
      <h2 className="text-center mb-4">Leader Board</h2>
      <div className="text-center">
        {users.map((user, index) => (
          <div key={index} className="py-2 border-bottom" style={{color: "rgb(231 196 59)"}}>
            <span className="fw-bold">{user.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
